.sc-bcXHqe h1 {
    color: #0321aa !important;
    font-size: 1vw !important;
    padding: 5vh 7vw 0 10vw !important;
    text-align: right !important;
}

@media (max-width: 767px) {
    .sc-bcXHqe h1 {
        font-size: 3vw !important;
        padding: 5vh 0vw 0 0vw !important;
    }
}