@media (min-width: 768px) {
    .MuiCard-root{
        max-width: 30%;
    }
    .MuiPaper-root{
        width: 100%;
        margin: 0 1vw 0 1vw;
    }
}

@media (max-width: 767px) {
    .MuiCard-root{
        margin: 30px 0 30px 0;
    }
}

.MuiTypography-root{
    text-align: left;
}
