.intro-block {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    padding: 0 4vw 0 4vw;
}

.image-block{
    max-width: 50%;
    align-self: center;
}

.image {
    border-radius: 50%;
}

.text {
    max-width: 50%;
    text-align: left;
}

.gretting{
    font-size: 6vw;
    font-style: oblique;
}

@media (min-width: 768px) {
    .theme-block {
        display: flex;
        justify-content: space-evenly;
        margin: 50px 0 50px 0;
        padding: 0 3vw 3vw 4vw;
    }
    .image {
        width: 60%;
    }
    .who-is-k{
        font-size: 4vw;
    }
    #Introduction, #Life, #Work {
        padding: 0 12vw 4vw 12vw;
        text-align: left;
    }
    .sentence-today{
        font-size: 1.5vw;
    }
}

@media (max-width: 767px) {
    .theme-block {
        justify-content: space-evenly;
        margin: 50px 0 50px 0;
        padding: 0 3vw 3vw 3vw;
        text-align: -webkit-center;
    }
    .image {
        width: 70%;
    }
    .who-is-k{
        font-size: 5vw;
    }
    #Introduction, #Life, #Work {
        padding: 0 6vw 0 6vw;
        text-align: left;
    }
    .sentence-today{
        font-size: 4vw;
    }
}

hr{
    width: 50%;
    border: 1px solid rgba(128, 128, 128, 0.26);
    margin-bottom: 50px;
}

.no-bold{
    font-weight: normal;
}
