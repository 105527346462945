h1 {
    padding: 5vh 0 0 0;
}

.MuiBox-root {
    width: 100% !important;
}

@media (min-width: 768px) {
    .MuiBox-root {
        margin-top: 7vh;
    }
}