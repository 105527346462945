@media (min-width: 768px) {
    .work-text{
        font-size: 1.3vw;
    }
    .work {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        padding: 0 4vw 0 4vw;
    }
}

@media (max-width: 767px) {
    .work-text{
        font-size: 1rem;
        max-width: 100%;
        margin-bottom: 80px;
    }
    .work {
        justify-content: space-between;
        margin-top: 50px;
        padding: 0 4vw 0 4vw;
    }
    .work-Experience ul{
        padding-left: 10px;
    }
}