.navbar-nav {
    display: inline-block;
}

.navbar-brand {
    margin: 3vw 3vw 3vw 4vw;
    text-decoration: none;
    font-style: oblique;
    font-size: calc(10px + 3vmin);
    color: black;
}

.nav-link {
    margin-left: 2vw;
    text-decoration: none;
    font-size: calc(10px + 1.5vmin);
    color: gray;
}

.nav-link:hover {
    color: black;
}