li{
    margin: 30px 0 30px 0;
}

.no-bullet {
    list-style-type: none;
}

.intro-image-grid-container{
    align-self: center;
}

@media (min-width: 768px) {
    .intro-image-block {
        text-align: right;
        display: grid;
    }
    .intro-image {
        width: 50%;
        border-radius: 10px;
    }
    .intro-text{
        font-size: 1.3vw;
    }
    .introduction {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        padding: 0 4vw 0 4vw;
    }
    .intro-greeting{
        text-align: center;
        margin: 3vw 0 3vw 0;
        font-family: Garamond, serif;
    }
}

@media (max-width: 767px) {
    .intro-image-block {
        text-align: center;
        display: grid;
    }
    .intro-image {
        width: 75%;
        border-radius: 5px;
        margin: 6vw 0 6vw 0;
    }
    .intro-text{
        font-size: 1rem;
        max-width: 100%;
    }
    .introduction {
        justify-content: space-between;
        margin-top: 50px;
        padding: 0 4vw 0 4vw;
    }
    .intro-greeting{
        text-align: center;
        margin: 6vw 0 6vw 0;
        font-family: Garamond, serif;
    }
}