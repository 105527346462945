@media (min-width: 768px) {
    .life {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        padding: 0 4vw 0 4vw;
    }
    .life-text{
        font-size: 1.3vw;
        max-width: 45%;
        padding-top: 3vh;
    }
    .life-images{
        max-width: 50%;
    }
}

@media (max-width: 767px) {
    .life {
        justify-content: space-between;
        margin-top: 50px;
        padding: 0 4vw 0 4vw;
    }
    .life-text{
        font-size: 1rem;
        max-width: 100%;
    }
    .life-text ul{
        padding-left: 0px;
    }
    .life-images ul{
        width: 100%;
    }
}